@mixin mobile {
  @media screen and (max-width: 480px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}
@mixin laptop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

.formContainer {
  background-color: #a7bcff;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .formWrapper {
    background-color: white;
    padding: 20px 60px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    .logo {
      color: #5d5b8d;
      font-weight: bold;
      font-size: 24px;

    }

    .title {
      color: #5d5b8d;
      font-size: 12px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 15px;

      input {
        padding: 15px;
        border: none;
        width: 250px;
        border-bottom: 1px solid #a7bcff;
        &::placeholder {
          color: rgb(175, 175, 175);
        }
      }

      button {
        background-color: #7b96ec;
        color: white;
        padding: 10px;
        font-weight: bold;
        border: none;
        cursor: pointer;
      }

      label {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #8da4f1;
        font-size: 12px;
        cursor: pointer;

        img {
          width: 32px;
        }
      }
    }
    p {
      color: #5d5b8d;
      font-size: 12px;
      margin-top: 10px;
    }
  }
}

.home {
  background-color: #fff;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    border: 1px solid white;
    border-radius: 10px;
    width: 100%;
    height: 85vh;
    display: flex;
    overflow: hidden;
    
 
  

    .sidebar {
      flex: 1;
      background-color: #184e77ff;
      position: relative;

      .navbar {
        display: flex;
        align-items: center;
        background-color: #184e77ff;
        height: 80px;
        padding: 10px;
        border-bottom: solid #fff 1px;
        justify-content: space-between;
        color: #ddddf7;

        .logo {
          font-weight: bold;
          @include tablet {
            display: none;
          }
        }

        .user {
          display: flex;
          gap: 10px;
          align-items: center;

          img {
            background-color: #ddddf7;
            height: 65px;
            width: 65px;
            border-radius: 50%;
            object-fit: cover;
          }

          span{
            font-weight: bold;
            font-size: x-large;
          }

          button {
            background-color: #5d5b8d;
            color: #ddddf7;
            font-size: 10px;
            border: none;
            cursor: pointer;
            @include tablet {
              position: absolute;
              bottom: 10px;
            }
          }
        }
      }
      .search {
        border: 1px solid gray;
        border-radius: 10px;
        margin: 5px;

        .searchForm {
          padding: 10px;

          input {
            background-color: transparent;
            border: none;
            color: white;
            outline: none;

            &::placeholder {
              color: lightgray;
            }
          }
        }
      }

      .userChat {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: white;
        cursor: pointer;
        border-bottom: solid #fff 1px;
        border-top: solid #fff 1px;
     
        &:hover {
          background-color: #2f2d52;
        }

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
        }

        .userChatInfo {
          span {
            font-size: 18px;
            font-weight: 500;
          }
          p {
            font-size: 14px;
            color: lightgray;
          }
        }
      }
    }
    .chat {
      flex: 2;
      border-bottom: solid #184e77ff 1px;
      border-right: solid #184e77ff 1px;
      padding: 0;
      border-right-width: 0px;     border-bottom-width: 0px;

      

      .chatInfo {
        height: 80px;
        background-color: #184e77ff;
        border-left: solid #fff 1px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        padding: 10px;
        color: lightgray;
      }

    

        img {
          height: 65px;
          width: 65px;
          border-radius: 50%;
          object-fit: cover;
        }

        span{
          font-weight: 600;
          font-size: larger;
        }
    

      .messages {
        background-image: url('../public/seamless-background-pattern-with-hearts-2802587.jpeg');
        backdrop-filter: blur(0.15rem);
        background-size: cover;
        background-repeat: no-repeat;
        width: 102%;
        height: calc(100% - 161px);
        overflow: scroll;

        .message {
          display: flex;
          gap: 20px;
         
            align-items: center;
            padding-right: 5px;
          

          .messageInfo {
            display: flex;
            flex-direction: column;
            color: gray;
            font-weight: 300;
           

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
          .messageContent {
            max-width: 80%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 0;
            background: transparent;
            border: none;
            

            p {
              background-color: white;
              padding: 10px 20px;
              border-radius: 0px 10px 10px 10px;
              max-width: max-content;
            }

            img {
              width: 50%;
            }
          }

          &.owner {
            flex-direction: row-reverse;
            margin-bottom: 0;
            align-items: center;
            padding-right: 5px;

            .messageContent {
              align-items: flex-end;
              p {
                background-color: #184e77ff;
                color: white;
                border-radius: 10px 0px 10px 10px;
              }
            }
          }
        }
      }

      .input {
        height: 40px;
        background-color: white;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: solid #184e77ff 1px;

        input {
          width: 100%;
          border: none;
          outline: none;
          color: #2f2d52;
          font-size: 18px;

          &::placeholder {
            color: lightgray;
          }
        }

        .send {
          display: flex;
          align-items: center;
          gap: 10px;

        svg{
          fill: white;
          width: 25px;
          height: 25px;

        }



          button {
            border: none;
            padding: 10px 15px;
            color: white;
            background-color: #184e77ff;
            cursor: pointer;
            border-radius: 10px;
          }
        }
      }
    }
  }
}

.outset{
  display: flex;
  flex-direction: column;
  width: 100%;
    height: 95vh;
    gap: 8px;
}

.outset button{
  border-radius: 10px;
  background-color: #184e77ff;
  color: #fff;
  padding: 10px;
  width: 80px;
  border-style: none;
  cursor: pointer;
}


